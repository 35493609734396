

















































































.card-wrapper {
  max-width: 1024px;
}

.preview-container {
  display: flex;
  justify-content: center;
}

.coupon-section {
  .coupon-types {
    .card-img-top {
      max-width: 100%;
      width: 150px;
      margin: 0 auto;
      min-height: 150px;
    }
  }
}

.preview-actions {
  margin-top: 30px;
  border-top: 1px solid rgba(204, 204, 204, 0.5);
  padding-top: 30px;
  .btn {
    width: 150px;
  }
}
